/* eslint-disable react-hooks/rules-of-hooks */
import { useContext, useEffect, useRef } from 'react';
import { useFA } from './TwoFA';
import { userDataState, userPhoneNumberState } from '../../main';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase-setup/firebase';
import { sendVerificationSMS } from '../../utils/utilFuntions';

function TwoFAPage() {
  const inputRef = useRef<HTMLInputElement>(null);
  const { setValidFactor } = useFA();
  const { userPhoneNumber } = useContext(userPhoneNumberState);
  const { userData } = useContext(userDataState);
  const verificationCode = useRef<number | null>(null);
  const isCodeSent = useRef<boolean>(false);
  useEffect(() => {
    if (userPhoneNumber && !isCodeSent.current && userData) {
      const userDocRef = doc(db, 'clients', String(userData.ssn));
      const randomCode = Math.floor(100000 + Math.random() * 900000);
      isCodeSent.current = true;
      verificationCode.current = randomCode;
      updateDoc(userDocRef, { verificationCode: randomCode }).then(async () => {
        const twoFaBody = {
          phoneNumber: `+1${userPhoneNumber}`,
          message: `Your Transparent Price Rx verification code is ${randomCode}`,
        };
        await sendVerificationSMS(twoFaBody);
      });
    }
  }, [userData, userPhoneNumber]);
  return (
    <>
      <div className="login-c text-center">
        <h1>Authenticate Your Account</h1>
        <p className="subtitle">
          We have sent you an SMS with a code to the phone number ending in{' '}
          {userPhoneNumber?.toString().slice(-4)}.<br></br>Please enter it
          below.
        </p>
        <input type="number" ref={inputRef} />
        <div className="input-error">Incorrect code, please try again</div>
        <button
          className="cta-main"
          style={{ textAlign: 'center' }}
          onClick={() => {
            const inputValue = Number(inputRef.current?.value);
            if (inputValue === verificationCode.current) {
              const errorMessage = inputRef.current!
                .nextElementSibling as HTMLDivElement;
              errorMessage.classList.remove('active');
              setValidFactor(true);
            } else {
              const errorMessage = inputRef.current!
                .nextElementSibling as HTMLDivElement;
              errorMessage.classList.add('active');
            }
          }}>
          Submit
        </button>
      </div>
    </>
  );
}
export default TwoFAPage;
