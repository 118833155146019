import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase-setup/firebase';
import { collection, DocumentData, getDocs } from 'firebase/firestore';
import {
  Alert,
  Autocomplete,
  Button,
  createFilterOptions,
  FormControl,
  IconButton,
  ListItem,
  ListItemText,
  Snackbar,
  Stack,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCallback, useEffect, useState } from 'react';
import FaxUploaderComponenet from './FaxUploaderComponenet';
import { storage } from '../../firebase-setup/firebase';
import {
  ref,
  uploadBytes,
  listAll,
  getMetadata,
  StorageReference,
  deleteObject,
} from 'firebase/storage';

function FaxUploader() {
  const [clientsData, loadingClientsData] = useCollection(
    collection(db, 'clients'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const [loadingFiles, setLoadingFiles] = useState(false);

  const [meds, loadingMeds] = useCollection(collection(db, 'medications'), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const [brandMeds, setBrandMeds] = useState<string[]>([]);

  const [clientList, setClientList] = useState<string[]>([]);
  const [selectedClient, setSelectedClient] = useState<string>('');

  const [hpList, setHpList] = useState<{ docName: string; docFax: number }[]>(
    []
  );
  const [ordersList, setOrdersList] = useState<
    { docName: string; medication: string }[]
  >([]);
  const [tableData, setTableData] = useState<
    | {
        hp: {
          docName: string;
          docFax: number;
        };
        type: string;
        faxes: {
          required: boolean;
          name: string;
        }[];
      }[]
    | null
  >(null);
  const [allFiles, setAllFiles] = useState<{
    [key: string]: { files: File[]; id: string; hpFax: number }[];
  }>({});
  const [isValid, setIsValid] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<
    'success' | 'error' | 'loading' | null
  >(null);
  const [resetFlag, setResetFlag] = useState(false);
  const [clientFiles, setClientFiles] = useState<
    {
      file: StorageReference;
      emailName: string;
      docName: string;
    }[]
  >([]);
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
  });
  const getClientHpList = useCallback(async (clientDoc: DocumentData) => {
    const hpCollectionRef = collection(
      db,
      `clients/${clientDoc.id}/healthcare-providers`
    );
    const hpSnapshot = await getDocs(hpCollectionRef);
    const hpList = hpSnapshot.docs
      .map((doc) => ({
        docName:
          doc.data().mname === '' || doc.data().mname === ' '
            ? doc.data().fname + ' ' + doc.data().lname
            : doc.data().fname +
              ' ' +
              doc.data().mname +
              ' ' +
              doc.data().lname,
        docFax: Number(doc.data().fax.replace(/\D/g, '')),
      }))
      .filter((hp) => hp.docName !== '' && hp.docName !== ' ');

    setHpList(hpList);
  }, []);

  const getClientOrderList = useCallback(async (clientDoc: DocumentData) => {
    const orderCollectionRef = collection(db, `clients/${clientDoc.id}/orders`);
    const orderSnapshot = await getDocs(orderCollectionRef);
    const orderList = orderSnapshot.docs.map((doc) => {
      return {
        docName: doc.data().doctorName,
        medication: doc.data().medicationName,
      };
    });
    setOrdersList(orderList);
  }, []);

  const getFilesFromStorage = useCallback(async (clientName: string) => {
    setLoadingFiles(true);
    try {
      const storageRef = ref(storage, `/clientFiles/${clientName}`);
      const files = await listAll(storageRef);
      const filesMetadata = files.items.map(async (fileRef) => {
        const metadata = await getMetadata(fileRef);
        return metadata.customMetadata;
      });
      const filesMetadataResolved = await Promise.all(filesMetadata);
      const clientFiles = files.items.map((fileRef, index) => {
        return {
          file: fileRef,
          emailName: filesMetadataResolved[index]!.emailName,
          docName: filesMetadataResolved[index]!.docName,
        };
      });
      setClientFiles(clientFiles);
    } catch (error) {
      console.error('Error fetching files from storage:', error);
    } finally {
      setLoadingFiles(false);
    }
  }, []);

  const scheduleFaxTasks = async (
    faxes: { files: File[]; id: string; hpFax: number }[]
  ) => {
    const tasks = faxes.map((fax) => ({
      id: fax.id,
      files: fax.files,
      docFax: fax.hpFax,
    }));

    try {
      // Schedule tasks here
      console.log('Scheduling batch tasks:', tasks);
      console.log('All tasks scheduled successfully');
    } catch (error) {
      console.error('Error scheduling batch tasks:', error);
    }
  };

  const handleClientChange = useCallback(
    async (value: string) => {
      if (!clientsData) return;
      setSelectedClient(value);
      const clientDoc = clientsData!.docs.find((doc) => {
        const data = doc.data();
        return (
          data.fname + ' ' + data.lname + ' (' + data.email + ')' === value
        );
      });

      if (clientDoc) {
        getClientHpList(clientDoc);
        getClientOrderList(clientDoc);
        getFilesFromStorage(value.replace(' ', '_').toLowerCase());
      } else {
        setHpList([]);
      }
    },
    [clientsData, getClientHpList, getClientOrderList, getFilesFromStorage]
  );

  const medicationType = useCallback(
    (orders: string[], brandMeds: string[]) => {
      let segment = '';
      orders.forEach((order) => {
        if (!order || order === '') return;
        const fixedMedName = order.slice(0, order.indexOf(' ('));
        if (
          brandMeds.find((med) => med === fixedMedName) ||
          brandMeds.find((med) => med === fixedMedName.split(' ')[0])
        ) {
          if (segment === 'Generic') {
            segment = 'Both';
            return;
          }
          segment = 'Brand';
          return;
        } else {
          if (segment === 'Brand' || segment === 'Both') {
            segment = 'Both';
            return;
          }
          segment = 'Generic';
        }
      });
      return segment;
    },
    []
  );

  const createTableData = useCallback(
    (
      hpList: { docName: string; docFax: number }[],
      ordersList: { docName: string; medication: string }[]
    ) => {
      const rows: {
        hp: {
          docName: string;
          docFax: number;
        };
        type: string;
        faxes: {
          required: boolean;
          name: string;
        }[];
      }[] = [];

      hpList.forEach((hp) => {
        const hpMeds = ordersList
          .map((order) => {
            if (order.docName === hp.docName) return order.medication;
          })
          .filter((med): med is string => med !== undefined);
        const type = medicationType(hpMeds, brandMeds);
        const row = {
          hp: {
            docName: hp.docName,
            docFax: hp.docFax,
          },
          type: type,
          faxes: [
            {
              required: !clientFiles.some(
                (file) =>
                  file.docName === hp.docName &&
                  file.emailName === 'welcomeEmail'
              ),
              name: 'welcomeEmail',
            },
            {
              required: !clientFiles.some(
                (file) =>
                  file.docName === hp.docName && file.emailName === 'firstFax'
              ),
              name: 'firstFax',
            },
            {
              required: !clientFiles.some(
                (file) =>
                  file.docName === hp.docName &&
                  file.emailName === 'faxReminder'
              ),
              name: 'faxReminder',
            },
          ],
        };
        console.log(row);
        rows.push(row);
      });
      rows.forEach((row) => {
        setAllFiles((prev) => ({
          ...prev,
          [row.hp.docName]: [],
        }));
      });
      setTableData(rows);
    },
    [brandMeds, clientFiles, medicationType]
  );

  const handleFilesAdded = (
    hpName: string,
    hpFax: number,
    id: string,
    files: File[]
  ) => {
    setAllFiles((prev) => {
      const updatedFiles = { ...prev };
      if (!updatedFiles[hpName]) {
        updatedFiles[hpName] = [];
      }
      updatedFiles[hpName].push({ files, id, hpFax });
      return updatedFiles;
    });
  };
  const handleFileRemove = (hpName: string, index: number) => {
    const updatedFiles = [...allFiles[hpName]];
    updatedFiles.splice(index, 1);
    setAllFiles((prev) => ({
      ...prev,
      [hpName]: updatedFiles,
    }));
  };

  const handleUploadAllFiles = async () => {
    const selectClientName = selectedClient.replace(' ', '_').toLowerCase();
    const allFileLists = Object.values(allFiles).flat();
    const scheduledFaxes = allFileLists.filter(
      (file) => file.id !== 'welcomeEmail'
    );
    if (allFileLists.length === 0) {
      console.error('No files to upload.');
      return;
    }

    try {
      setUploadStatus('loading'); // Set loading status

      const uploadTasks = allFileLists.map((fileObject) =>
        uploadFileGroup(fileObject, selectClientName)
      );

      await Promise.all(uploadTasks); // Wait for all uploads to complete
      setUploadStatus('success'); // Set success status
      resetUploader(); // Reset uploader after successful upload
      getFilesFromStorage(selectClientName); // Refresh file list
      scheduleFaxTasks(scheduledFaxes);
      setIsValid(false);
    } catch (error) {
      console.error('Error uploading files:', error);
      setUploadStatus('error'); // Set error status
    }
  };

  /**
   * Uploads all files in a file group with proper metadata.
   */
  const uploadFileGroup = async (
    fileObject: { files: File[]; id: string },
    clientName: string
  ) => {
    try {
      const uploadPromises = fileObject.files.map((file) =>
        uploadFile(file, fileObject.id, clientName)
      );

      await Promise.all(uploadPromises); // Upload all files in parallel
      console.log(
        `All files for group ${fileObject.id} uploaded successfully.`
      );
    } catch (error) {
      console.error(`Error uploading file group ${fileObject.id}:`, error);
      throw error; // Rethrow to propagate error to the main handler
    }
  };

  /**
   * Uploads a single file to Firebase Storage with metadata.
   */
  const uploadFile = async (
    file: File,
    emailName: string,
    clientName: string
  ) => {
    const storageRef = ref(storage, `/clientFiles/${clientName}/` + file.name);
    const docName = Object.keys(allFiles).find((key) =>
      allFiles[key].some((fileObj) => fileObj.files.includes(file))
    );

    if (!docName) {
      throw new Error(`Document name not found for file: ${file.name}`);
    }

    const metaData = {
      customMetadata: {
        emailName: emailName || '-',
        docName: docName || '-',
      },
    };

    try {
      await uploadBytes(storageRef, file, metaData);
      console.log(`File uploaded: ${file.name}`);
    } catch (error) {
      console.error(`Failed to upload file: ${file.name}`, error);
      throw error; // Propagate the error
    }
  };
  const uploadStatusMessage = () => {
    return (
      <Snackbar
        open={!!uploadStatus}
        autoHideDuration={3000}
        onClose={() => setUploadStatus(null)} // Reset status on close
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert
          onClose={() => setUploadStatus(null)}
          severity={
            uploadStatus === 'success'
              ? 'success'
              : uploadStatus === 'loading'
              ? 'info'
              : 'error'
          }
          sx={{ width: '100%' }}>
          {uploadStatus === 'success'
            ? 'All files uploaded successfully!'
            : uploadStatus === 'loading'
            ? 'Uploading files...'
            : 'Error uploading files. Please try again.'}
        </Alert>
      </Snackbar>
    );
  };

  const resetUploader = () => {
    setAllFiles((prev) => {
      const updatedFiles = { ...prev };
      Object.keys(updatedFiles).forEach((key) => {
        updatedFiles[key] = [];
      });
      return updatedFiles;
    });
    setIsValid(false);
    setResetFlag(true); // Trigger reset in child components
  };

  const handleResetComplete = () => {
    setResetFlag(false); // Clear the reset flag after reset is applied
  };

  useEffect(() => {
    if (!loadingClientsData) {
      const orderedList = clientsData!.docs.map((doc) => {
        if (
          doc.data().insurance.company === 'medicare' ||
          doc.data().employed === 'No Income in Household'
        )
          return (
            doc.data().fname +
            ' ' +
            doc.data().lname +
            ' (' +
            doc.data().email +
            ')'
          );
      });

      orderedList.sort((a, b) => {
        if (a! > b!) return 1;
        if (a! < b!) return -1;
        return 0;
      });
      setClientList(
        orderedList.filter((client) => client !== undefined) as string[]
      );
    }
  }, [loadingClientsData, clientsData]);
  useEffect(() => {
    if (!loadingMeds && meds) {
      meds.docs.map((doc) => {
        doc.data().brand && setBrandMeds((prev) => [...prev, doc.data().name]);
      });
    }
  }, [loadingMeds, meds]);

  useEffect(() => {
    if (hpList.length > 0 && ordersList.length > 0) {
      createTableData(hpList, ordersList);
    }
  }, [hpList, ordersList, createTableData]);

  useEffect(() => {
    if (selectedClient && tableData) {
      let isValid = true;
      console.log(allFiles);
      console.log(tableData);
      tableData.forEach((row) => {
        row.faxes.forEach((fax) => {
          if (
            fax.required &&
            allFiles[row.hp.docName].every(
              (fileObject) => fileObject.id !== fax.name
            )
          ) {
            isValid = false;
            return;
          }
        });
      });

      setIsValid(isValid);
    }
  }, [allFiles, clientFiles.length, selectedClient, tableData]);

  return !loadingClientsData ? (
    <>
      <Stack
        className="container"
        sx={{
          mt: 2,
        }}>
        <Stack className="tprx-card">
          <Typography variant="h2">
            Clients Files Uploader (Medicare & No Income)
          </Typography>

          <Grid container spacing={2} width={'100%'} alignItems={'center'}>
            <Grid item sm={9}>
              <FormControl
                id="client"
                sx={{
                  width: '100%',
                }}>
                <Autocomplete
                  filterOptions={filterOptions}
                  disablePortal
                  options={clientList}
                  value={selectedClient || null}
                  onChange={(_e, value) => {
                    handleClientChange(value as string);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      name={'client'}
                      value={selectedClient || null}
                      label={'Select Client'}
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item sm={3}>
              <Button
                sx={{ width: '100%' }}
                variant="contained"
                onClick={handleUploadAllFiles}
                disabled={!isValid || uploadStatus === 'loading'}>
                Start Automation
              </Button>
            </Grid>
          </Grid>
          {!loadingFiles && selectedClient ? (
            <TableContainer
              sx={{
                display: 'flex',
              }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      width={'15%'}
                      sx={{
                        p: 2,
                      }}>
                      Doctor Name
                    </TableCell>
                    <TableCell
                      align="center"
                      width={'10%'}
                      sx={{
                        p: 2,
                      }}>
                      Type
                    </TableCell>
                    <TableCell
                      align="center"
                      width={'25%'}
                      sx={{
                        p: 2,
                      }}>
                      Welcome Email
                    </TableCell>
                    <TableCell
                      align="center"
                      width={'25%'}
                      sx={{
                        p: 2,
                      }}>
                      First Fax
                    </TableCell>
                    <TableCell
                      align="center"
                      width={'25%'}
                      sx={{
                        p: 2,
                      }}>
                      Fax Reminder
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((row, index) => (
                    <TableRow
                      key={row.hp.docName + index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <TableCell
                        component="th"
                        scope="row"
                        width={'15%'}
                        sx={{
                          p: 2,
                        }}>
                        {row.hp.docName}
                      </TableCell>

                      <TableCell
                        align="center"
                        width={'10%'}
                        sx={{
                          p: 2,
                        }}>
                        {row.type}
                      </TableCell>
                      {row.faxes.map((fax, faxIndex) => (
                        <TableCell
                          align="center"
                          width={'25%'}
                          sx={{
                            p: 2,
                          }}
                          key={`${row.hp.docName}-${fax.name}-${faxIndex}`}>
                          {clientFiles.some(
                            (file) =>
                              file.emailName === fax.name &&
                              file.docName === row.hp.docName
                          ) ? (
                            <>
                              <Typography variant="body2">
                                Exisitng Files:
                              </Typography>
                              <Stack spacing={1}>
                                {clientFiles
                                  .filter(
                                    (file) =>
                                      file.emailName === fax.name &&
                                      file.docName === row.hp.docName
                                  )
                                  .map((fileObject, index) => (
                                    <ListItem
                                      key={fileObject.emailName + index}
                                      secondaryAction={
                                        <IconButton
                                          color="error"
                                          edge="end"
                                          aria-label="delete"
                                          onClick={() => {
                                            deleteObject(fileObject.file).then(
                                              () => {
                                                getFilesFromStorage(
                                                  selectedClient
                                                    .replace(' ', '_')
                                                    .toLowerCase()
                                                );
                                              }
                                            );
                                          }}>
                                          <DeleteIcon />
                                        </IconButton>
                                      }>
                                      <ListItemText
                                        secondary={
                                          fileObject.file.name.length > 16
                                            ? `${fileObject.file.name.slice(
                                                0,
                                                16
                                              )}...`
                                            : fileObject.file.name
                                        }
                                      />
                                    </ListItem>
                                  ))}
                              </Stack>
                            </>
                          ) : (
                            <FaxUploaderComponenet
                              docName={row.hp.docName}
                              docFax={row.hp.docFax}
                              uploaderId={fax.name}
                              onFilesAdded={handleFilesAdded}
                              onFileRemove={(index: number) =>
                                handleFileRemove(row.hp.docName, index)
                              }
                              reset={resetFlag}
                              onResetComplete={handleResetComplete}
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : !selectedClient ? (
            <Typography variant="h4">
              Please select a client to view their files.
            </Typography>
          ) : (
            <div className="loader" />
          )}
        </Stack>
      </Stack>

      {uploadStatus && uploadStatusMessage()}
    </>
  ) : (
    <div className="loader-s">
      <div className="loader" />
    </div>
  );
}
export default FaxUploader;
