/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import formConfig from '../formConfig';
import DatePickerValue from './DateField';
import InsuranceFields from './InsuranceFields';
import MedicareFields from './MedicareFields';
import React, {
  useState,
  ChangeEvent,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { clientInfo, formConfigField, formValues } from '../../../utils/types';
import dayjs from 'dayjs';
import QualificationDialog from '../QualificationDialog';
import { DocumentData } from 'firebase/firestore';
import theme from '../../../theme';

const FirstStep = React.memo(
  ({
    isMedicare,
    setIsMedicare,
    setIsGeneric,
    values,
    setValues,
    setValidStep,
    insuranceDataList,
  }: {
    isMedicare: boolean;
    setIsMedicare: (value: boolean) => void;
    setIsGeneric: (value: boolean) => void;
    values: formValues;
    setValues: React.Dispatch<React.SetStateAction<formValues>>;
    setValidStep: React.Dispatch<React.SetStateAction<boolean>>;
    insuranceDataList: DocumentData[] | null;
  }) => {
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [prevValues, setPrevValues] = useState(values);
    const [prevErrors, setPrevErrors] = useState(errors);

    const [incomeValue, setIncomeValue] = useState<string | null>(
      values.patient.income.toString() !== ''
        ? '$' + parseInt(values.patient.income.toString()).toLocaleString()
        : ''
    );
    const [requiredInsurnaceFields, setRequiredInsurnaceFields] = useState<{
      [key: string]: boolean;
    }>({
      company: true,
      product: true,
      plan: true,
    });
    const [isMedicarePartD, setIsMedicarePartD] = useState<boolean>(true);
    const calculateAgeEligibility = useCallback(
      (dob: string, minMax: string, thresholdAge: number) => {
        const today = new Date();
        const dobDate = new Date(dob);

        if (isNaN(dobDate.getTime())) {
          return false;
        }
        // Calculate the age
        const diffYears = today.getFullYear() - dobDate.getFullYear();
        const diffMonths = today.getMonth() - dobDate.getMonth();
        const diffDays = today.getDate() - dobDate.getDate();
        if (minMax === 'min') {
          if (
            diffYears < thresholdAge ||
            (diffYears === thresholdAge && diffMonths < 0) ||
            (diffYears === thresholdAge && diffMonths === 0 && diffDays < 0)
          ) {
            return false;
          }
          return true;
        } else {
          if (diffYears > thresholdAge) {
            return false;
          } else if (diffYears === thresholdAge && diffMonths > 0) {
            return false;
          } else if (
            diffYears === thresholdAge &&
            diffMonths === 0 &&
            diffDays >= 0
          ) {
            return false;
          }
          return true;
        }
      },
      []
    );
    const [openAgeDialog, setOpenAgeDialog] = useState<boolean>(false);

    const handleClose = () => {
      setOpenAgeDialog(false);
    };

    const handleChangeBirthday = useCallback(
      (value: string) => {
        setValues((prevValues) => ({
          ...prevValues,
          patient: {
            ...prevValues.patient,
            dob: value,
          },
        }));
      },

      [setValues]
    );
    const requiredCheck = useCallback(() => {
      // Flag to track if errors or values actually change
      let validStepChanged = false;

      formConfig[0].fields!.forEach((field: formConfigField) => {
        const value = values.patient[field.name as keyof typeof values.patient];

        // If the field is required and the value is empty, set an error
        if (field.required && value === '') {
          if (errors[field.name] !== 'This field is required') {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [field.name]: 'This field is required',
            }));
          }
        } else if (
          field.type === 'date' &&
          dayjs(value as string).isBetween(
            dayjs().subtract(18, 'year'),
            dayjs()
          )
        ) {
          setOpenAgeDialog(true);
          if (errors[field.name] !== 'You must be 18 years old or older') {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [field.name]: 'You must be 18 years old or older',
            }));
          }
          setValidStep(false);
        } else {
          if (errors[field.name]) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [field.name]: '',
            }));
          }
        }
      });
      // Ensure that all required fields are filled before proceeding
      const allRequiredFieldsFilled = formConfig[0].fields!.every((field) => {
        if (field.required) {
          return (
            values.patient[field.name as keyof typeof values.patient] !== ''
          );
        }
        return true;
      });
      // Dynamic insurance field validation based on current state
      Object.keys(requiredInsurnaceFields).forEach((field) => {
        if (isMedicare) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: '',
          }));
          return;
        }
        if (
          requiredInsurnaceFields[field] &&
          values.patient.insurance[
            field as keyof typeof values.patient.insurance
          ] === ''
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required',
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: '',
          }));
        }
      });

      // Ensure all dynamically required insurance fields are filled
      const allRequiredInsuranceFieldsFilled = Object.keys(
        requiredInsurnaceFields
      ).every((field) => {
        if (!requiredInsurnaceFields[field]) return true;
        return (
          values.patient.insurance[
            field as keyof typeof values.patient.insurance
          ] !== ''
        );
      });

      const allRequiredMedicareFieldsFilled = formConfig[0].medicare!.every(
        (field) => {
          if (field.required) {
            if (!isMedicarePartD) {
              // Check only 'partD', 'advantage', and 'supplement' fields
              if (
                field.name === 'partD' ||
                field.name === 'advantage' ||
                field.name === 'supplement' ||
                field.name === 'medicareYear'
              ) {
                if (
                  field.name === 'advantage' &&
                  values.patient.medicare.advantage === 'yes'
                ) {
                  return false;
                }
                return (
                  values.patient.medicare[
                    field.name as keyof typeof values.patient.medicare
                  ] !== ''
                );
              }
              // If the field is not one of the specified ones, return true
              return true;
            } else {
              // When isMedicarePartD is true, check all required fields
              return (
                values.patient.medicare[
                  field.name as keyof typeof values.patient.medicare
                ] !== ''
              );
            }
          }
          return true;
        }
      );
      // If there are any errors, prevent moving to the next step
      if (Object.values(errors).some((error) => error !== '')) {
        setValidStep(false);
        return;
      }
      // Check if validStep needs to be updated
      if (isMedicare) {
        const newValidStep =
          allRequiredFieldsFilled &&
          allRequiredMedicareFieldsFilled &&
          Object.values(errors).every((error) => error === '');
        if (newValidStep !== validStepChanged) {
          validStepChanged = true;
          setValidStep(newValidStep);
        }
      } else {
        const newValidStep =
          allRequiredFieldsFilled &&
          allRequiredInsuranceFieldsFilled &&
          Object.values(errors).every((error) => error === '');
        if (newValidStep !== validStepChanged) {
          validStepChanged = true;
          setValidStep(newValidStep);
        }
      }
    }, [
      requiredInsurnaceFields,
      errors,
      isMedicare,
      isMedicarePartD,
      values,
      setValidStep,
    ]);
    const handleChange = useCallback(
      (e: { target: { name: string; value: unknown } }, object: clientInfo) => {
        const { name, value } = e.target;
        const boolValue =
          value === 'yes' ? true : value === 'no' ? false : value;

        // Update the correct field in the object
        const updatedObject = { ...object, [name]: boolValue };

        // Use a functional update to ensure the correct previous state

        setValues((prevValues) => ({
          ...prevValues,
          patient: {
            ...prevValues.patient,
            ...updatedObject,
          },
        }));
        requiredCheck();
      },
      [requiredCheck, setValues]
    );
    const handleIncomeChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/\D/g, '');

        if (value === '') {
          setIncomeValue('');
          setValues((prevValues) => ({
            ...prevValues,
            patient: {
              ...prevValues.patient,
              income: 0, // Ensure the income is set to 0 in formValues
            },
          }));
        } else {
          const formattedValue = '$' + parseInt(value).toLocaleString();
          setIncomeValue(formattedValue);

          setValues((prevValues) => ({
            ...prevValues,
            patient: {
              ...prevValues.patient,
              income: parseInt(value), // Ensure the income is updated in formValues
            },
          }));
        }
        requiredCheck();
      },
      [requiredCheck, setValues]
    );

    const MedicareFieldsComponent = useMemo(() => {
      return isMedicare ? (
        <MedicareFields
          values={values}
          setValues={setValues}
          errors={errors}
          requiredCheck={requiredCheck}
          setValidStep={setValidStep}
          isMedicarePartD={isMedicarePartD}
          setIsMedicarePartD={setIsMedicarePartD}
        />
      ) : null;
    }, [
      isMedicare,
      values,
      setValues,
      errors,
      requiredCheck,
      setValidStep,
      isMedicarePartD,
    ]);

    const InsuranceFieldsComponent = useMemo(() => {
      return !isMedicare ? (
        <InsuranceFields
          setIsGeneric={setIsGeneric}
          values={values}
          setValues={setValues}
          insuranceDataList={insuranceDataList}
          setRequiredInsurnaceFields={setRequiredInsurnaceFields}
        />
      ) : null;
    }, [isMedicare, setIsGeneric, values, setValues, insuranceDataList]);
    const datePickerComponent = useMemo(() => {
      return (
        <DatePickerValue
          setOpenAgeDialog={setOpenAgeDialog}
          onChange={handleChangeBirthday}
          setIsMedicare={setIsMedicare}
          calculateAgeEligibility={calculateAgeEligibility}
          disability={values.patient.disabled}
          errors={errors}
          setErrors={setErrors}
          values={values}
        />
      );
    }, [
      setOpenAgeDialog,
      handleChangeBirthday,
      setIsMedicare,
      calculateAgeEligibility,
      errors,
      setErrors,
      values,
    ]);

    useEffect(() => {
      setIncomeValue(
        values.patient.income.toString() !== ''
          ? '$' + parseInt(values.patient.income.toString()).toLocaleString()
          : ''
      );
    }, [values.patient.income]);

    useEffect(() => {
      // Call the check only if the values or errors have actually changed
      const shouldRunCheck =
        JSON.stringify(values) !== JSON.stringify(prevValues) ||
        JSON.stringify(errors) !== JSON.stringify(prevErrors);

      if (shouldRunCheck) {
        requiredCheck();
        setPrevValues(values);
        setPrevErrors(errors);
      }
    }, [values, errors, requiredCheck, prevValues, prevErrors]);

    useEffect(() => {
      isMedicare &&
        setValues((prev) => ({
          ...prev,
          patient: {
            ...prev.patient,
            insurance: {
              company: 'medicare',
              product: '',
              plan: '',
              policy: '',
            },
          },
        }));
    }, [isMedicare, setValues]);
    return (
      <>
        <Typography variant="h1" textAlign={'center'}>
          Welcome to TPRX pharmacy program onboarding
        </Typography>
        <Stack>
          <Typography
            variant="h2"
            textAlign={'center'}
            color={theme.palette.primary.main}>
            Let's quickly determine the best program for you
          </Typography>
          <Typography variant="h3" textAlign={'center'}>
            Your answers here will guide us to tailor everything perfectly to
            your needs
          </Typography>
        </Stack>
        <Stack className="tprx-card">
          <Grid container spacing={2}>
            {formConfig[0].fields.map((field) => (
              <Grid item xs={12} sm={4} key={field.name}>
                {(() => {
                  switch (field.type) {
                    case 'income':
                      return (
                        <TextField
                          required
                          error={errors['income'] ? true : false}
                          helperText={errors['income']}
                          id="income"
                          name="income"
                          label={
                            isMedicare
                              ? 'Household Annual Social security Income'
                              : 'Yearly Income'
                          }
                          onChange={handleIncomeChange}
                          value={
                            values.patient.income.toString() !== ''
                              ? incomeValue
                              : ''
                          }
                          inputProps={{
                            inputMode: 'numeric',
                          }}
                          margin="normal"
                          fullWidth
                        />
                      );
                    case 'date':
                      return datePickerComponent;
                    case 'radio':
                      return (
                        <>
                          <FormControl required={field.required}>
                            <FormLabel id="disabled">{field.label}</FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="disabled"
                              value={
                                values.patient.disabled === true ? 'yes' : 'no'
                              }
                              name="disabled"
                              sx={{ mt: 2 }}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleChange(e, values.patient);
                                if (e.target.value === 'yes') {
                                  setIsMedicare(true);
                                } else {
                                  setIsMedicare(
                                    calculateAgeEligibility(
                                      values.patient.dob,
                                      'min',
                                      65
                                    )
                                  );
                                }
                              }}>
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                                sx={{
                                  pr: 2,
                                  border: '1px solid #d9d9d9',
                                  borderRadius: '4px',
                                  backgroundColor: '#fff',
                                }}
                              />
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                                sx={{
                                  pr: 2,
                                  border: '1px solid #d9d9d9',
                                  borderRadius: '4px',
                                  backgroundColor: '#fff',
                                }}
                              />
                            </RadioGroup>
                          </FormControl>
                        </>
                      );
                    default:
                      return (
                        <TextField
                          required={field.required}
                          label={field.label}
                          name={field.name}
                          type={field.type}
                          value={
                            values.patient[field.name as keyof clientInfo] || ''
                          }
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (field.name === 'numinhouse') {
                              if (parseInt(e.target.value) > 99) {
                                return;
                              }
                            }

                            handleChange(e, values.patient);
                          }}
                          error={errors[field.name] ? true : false}
                          helperText={errors[field.name]}
                          margin="normal"
                          fullWidth
                        />
                      );
                  }
                })()}
              </Grid>
            ))}
            <Divider sx={{ width: '100%', mt: 4, mb: 4 }} />
            {MedicareFieldsComponent}
            {InsuranceFieldsComponent}
          </Grid>
        </Stack>
        <QualificationDialog
          open={openAgeDialog}
          handleClose={handleClose}
          qulificationGroup={'who are under 18'}
          isEligible={false}
        />
      </>
    );
  }
);

export default FirstStep;
