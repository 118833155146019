import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  styled,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
function FileUploader({
  docName,
  docFax,
  uploaderId,
  onFilesAdded,
  onFileRemove,
  reset,
  onResetComplete,
}: {
  docName: string;
  docFax: number;
  uploaderId: string;
  onFilesAdded: (
    hpName: string,
    hpFax: number,
    id: string,
    files: File[]
  ) => void;
  onFileRemove: (index: number) => void;
  reset: boolean;
  onResetComplete: () => void;
}) {
  const [files, setFiles] = useState<File[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const uploadedFiles = Array.from(event.target.files);
      setFiles(uploadedFiles);
      onFilesAdded(docName, docFax, uploaderId, uploadedFiles);
    }
  };

  const handleFileRemove = (index: number) => {
    onFileRemove(index);
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  useEffect(() => {
    if (reset) {
      setFiles([]);
      onResetComplete();
    }
  }, [reset, onResetComplete]);
  return (
    <Box
      sx={{
        width: '100%',
        margin: '0 auto',
        textAlign: 'center',
      }}>
      <Button
        sx={{
          pt: 1,
          pb: 1,
          pl: 2,
          pr: 2,
        }}
        size="small"
        variant="contained"
        component="label"
        startIcon={<UploadFileIcon />}>
        Upload
        <VisuallyHiddenInput
          type="file"
          hidden
          multiple
          onChange={handleFileChange}
          accept=".pdf" // Adjust accepted file types as needed
        />
      </Button>

      {files.length > 0 && (
        <>
          <List
            sx={{
              marginTop: 2,
              padding: 0,
            }}>
            {files.map((file, index) => (
              <ListItem
                sx={{
                  p: 1,
                  m: 0,
                }}
                key={file.name + index}
                secondaryAction={
                  <IconButton
                    size="small"
                    color="error"
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleFileRemove(index)}>
                    <DeleteIcon />
                  </IconButton>
                }>
                <ListItemText
                  secondary={
                    file.name.length > 24
                      ? `${file.name.slice(0, 24)}...`
                      : file.name
                  }
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  );
}

export default FileUploader;
