import { Button, Drawer, Stack, Typography } from '@mui/material';
import welcomeImg from '../../../assets/images/welcome.webp';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { navigateWithQuery } from '../../../utils/utilFuntions';
import { useState } from 'react';

function WelcomScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  return (
    <>
      <Stack
        justifyContent={{
          xs: 'flex-start',
          md: 'center',
        }}
        sx={{
          backgroundImage: 'linear-gradient( #89aeb765, var(--background) 35%)',
          height: 'calc(100vh - 6.4rem)',
        }}>
        <Stack
          className="container"
          sx={{
            paddingTop: {
              xs: 4,
              md: 8,
            },
          }}>
          <Stack
            spacing={{
              xs: 4,
              md: 12,
            }}
            direction={{
              xs: 'column',
              md: 'row',
            }}
            alignItems={'center'}>
            <Stack
              width={'100%'}
              spacing={{
                xs: 2,
                md: 4,
              }}>
              <Stack spacing={2}>
                <Typography
                  variant="h1"
                  fontSize={{
                    xs: '3rem',
                    md: '4rem',
                  }}>
                  Transparent and Seamless Medication Procurement
                </Typography>
                <Typography variant="h3" fontWeight={400} lineHeight={1.5}>
                  We provide efficient access to affordable and accessible
                  medications to all Americans who have been prescribed
                  medications they simply cannot afford.
                </Typography>
                <Typography variant="h3" fontWeight={400} lineHeight={1.5}>
                  We are working directly with you, your healthcare provider,
                  and the pharmaceutical manufacturers' pharmacy.
                </Typography>
              </Stack>
              <Stack
                direction={{
                  xs: 'column',
                  md: 'row',
                }}
                spacing={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    navigateWithQuery(navigate, '/enroll', location)
                  }>
                  Become a Member
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setOpen(true);
                  }}>
                  Learn More
                </Button>
              </Stack>
            </Stack>
            <Stack
              width={'100%'}
              justifyContent={'center'}
              alignItems={'center'}>
              <img src={welcomeImg} alt="medications" width="500" />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Stack
          justifyContent={'center'}
          alignItems={'end'}
          p={{
            xs: 2,
            md: 6,
          }}
          spacing={2}
          sx={{
            width: {
              sm: '100%',
              md: '60vw',
            },
            position: 'relative',
          }}
          role="presentation">
          <Button onClick={toggleDrawer(false)} variant="contained">
            Close
          </Button>
          <Stack>
            <Typography variant="h2" mb={1}>
              No Cost Medication Program
            </Typography>
            <Typography variant="body1" mb={4}>
              This program is for clients who don’t have any insurance coverage
              for the medication they need. If your insurance doesn't cover the
              medication at all, this program may help. Each medication has its
              own income limits, so be sure to check if you qualify using our{' '}
              <Link to={''} onClick={toggleDrawer(false)}>
                Eligibility Calculator.
              </Link>
            </Typography>
            <Typography variant="h2" mb={1}>
              Generic Program
            </Typography>
            <Typography variant="body1" mb={4}>
              If you need generic medications, we offer the best prices through
              a nonprofit partner. No matter what kind of insurance you have
              (including Medicare), or your income level, you qualify for this
              program.
            </Typography>
            <Typography variant="h2" mb={1}>
              Medicare Part D Program
            </Typography>
            <Typography variant="body1" mb={4}>
              For clients on Medicare, this program helps you get brand-name
              medications for free. There’s no income requirement, so any
              Medicare client can take advantage of this program.
            </Typography>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
}
export default WelcomScreen;
